<template>
  <div>
    <el-row style="margin-bottom: 20px;">
      <el-col :span="24">
        <el-card>
          <search-filter
              :searchName="'搜索题目'"
              :keywordOptions="{placeholder:'请输入题目名称或题目编号'}"
              :searchOptions="whereOptions.filter(d=>['diff_id' , 'from_id' , 'type'].indexOf(d.options.prop) > -1)"
              :otherOptions="whereOptions.filter(d=>['diff_id' , 'from_id' , 'type'].indexOf(d.options.prop) === -1)"
              v-model="where"
              :total="total"
              @search="$refs.table.reset(...arguments)"
          ></search-filter>
        </el-card>
      </el-col>
      <!-- <el-col :span="6" :offset="1">
        <know-points :height="226"></know-points>
      </el-col> -->
    </el-row>
    <!-- <div class="operate">
      <el-button type="primary" size="small"
           style="margin-bottom: 15px"
           v-if="(teamInfo.isManager||teamInfo.is_member) && $isPowers('questionCreate')" @click="$refs.questionSave.visibled(true)"
      >
        创建题目
      </el-button>
    </div> -->
    <el-card>
      <com-table
          ref="table"
          :data="{
            url:'/xapi/user.group/questionList',
            params:{...$route.query,team_id}
          }"
          :columns="columns"
          :where="where"
          :total.sync="total"
          :rowStyle="()=>{return {cursor:'pointer'};}"
          @cell-click="(row)=>{isClick === false && $handleRoute({question_id:row.id},'TeamQuestionInfo')}"
      >
        <div @click.stop="isClick=true" slot="action" slot-scope="scope">
          <el-button type="text" size="small" @click="$handleRoute({question_id:scope.row.id},'TeamQuestionInfoComment')">评论</el-button>
          <!-- <el-button type="text" size="small" @click="$handleRoute({question_id:scope.row.id},'TeamQuestionCensus')">答题统计</el-button> -->
        </div>
      </com-table>
    </el-card>
    <diy-dialog class="question-save-dialog" ref="questionSave" :width="'70vw'" 
		:before-close="(c,t)=>{return t === 'confirm' ? ($refs.questionSaveForm.submitForm(()=>{$refs.table.reset();c&&c()}) , false) : true;}"
	>
      <div slot="title">
        新建题目
      </div>
      <question-save ref="questionSaveForm">
        <span slot="footer"></span>
      </question-save>
    </diy-dialog>
  </div>
</template>

<script>
import SearchFilter from "@/components/com/SearchFilter";
import KnowPoints from "@/views/api/com/knowPoints";
import QuestionSave from "@/views/api/teaching/question/save";
export default {
  props: {
    team_id:{
      default: () => 0,
    },
    teamInfo:{
      type:Object,
      default: () => { return {}; },
    },
  },
  components: {KnowPoints, SearchFilter,QuestionSave},
  watch:{
    isClick(v){
      v && this.$nextTick(()=>{
        this.isClick = false;
      })
    },
  },
  data(){
    return {
      total:0,
      where:{},
      isClick:false,
      whereOptions:[
        { name:'el-form-item' ,
          options:{label:'难度' , prop:'diff_id', type:'radio-group' , data:{ name:"question", keys:"diff_id" } , defaultValue :'全部' }
        },
        { name:'el-form-item' ,
          options:{label:'来源' , prop:'from_id', type:'radio-group'  , data:{ name:"question", keys:"from_id" } , defaultValue :'全部'}
        },
        { name:'el-form-item' ,
          options:{label:'类型' , prop:'type', type:'radio-group' , data:{ name:"question", keys:"type2" } , defaultValue :'全部'}
        },
        { name:'el-form-item' ,
          // options:{label:'时间' , prop:'date', type:'date-picker' , dataType:'date' , placeholder:'请选择时间'}
           options:{label:'年份' , prop:'date', type:'year' , placeholder:'请选择年份'}

        },
      ],
      columns:[
        {prop:'id',label:'编号',width:'80' , align:'center'},
        {prop:'title',label:'<span><font style="color:red;">类型</font> - <font style="color:green;">创建人</font> - 题目名称</span>',template:(scope)=>{
            return '<font style="color:red;">'+scope.row.type_str+'</font> - <font style="color:green;">'+scope.row.user_name+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
          },minWidth:'320px' , align:'center'},
        {prop:'grade',label:'分数',width:'80' , align:'center'},
        {prop:'time_str',label:'时间',width:'120' , align:'center'},
        {prop:'create_date',label:'创建时间',width:'160' , align:'center'},
        {fixed:'right',label:'操作',width:'160' , slot:'action' , align:'center'},
      ],
    }
  },
}
</script>

<style scoped>

</style>